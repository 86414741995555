import { Link } from "gatsby";
import React from "react";
import Logo from "../Logo";
// import config from "../../../content/meta/config";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// import Button from "react-bootstrap/Button";
// import Container from "react-bootstrap/Container";

// const PHunt = () => {
//   return (
//     <a
//       href="https://www.producthunt.com/posts/plagiashield?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-plagiashield"
//       target="_blank"
//       rel="noopener noreferrer"
//     >
//       <img
//         src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=210712&theme=light"
//         alt="PlagiaShield - Fight plagiarism ➡️ Rank better. For free. | Product Hunt Embed"
//         style={{ width: "250px", height: "54px" }}
//         // style="width: 250px; height: 54px;"
//         // width="250px"
//         // height="54px"
//       />
//     </a>
//   );
// };

const Header = () => (
  <Navbar bg="white" expand="md" sticky="top" className="m-2">
    <Navbar.Brand href="/" aria-label="plagiashield-logo">
      <Logo />
    </Navbar.Brand>

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse className="mr-auto justify-content-end align-items-center">
      <Nav>
        {/* <Link to="/pricing" className="text-dark" style={{ verticalAlign: "middle" }}> */}
        {/* <span>Pricing</span> */}
        {/* <Nav.Item className="ml-2 mb-1 text-center text-primary">
          <PHunt />
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link href="/pricing" className="mx-2 mb-1 text-center text-primary">
            <strong>Pricing</strong>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/blog" className="mx-2 mb-1 text-center text-primary">
            <strong>Blog</strong>
          </Nav.Link>
        </Nav.Item>
        {/* </Link> */}
        <Nav.Item>
          <Nav.Link
            href="https://app.plagiashield.com/app/login"
            className="mx-2 mb-1 bg-white text-center text-primary border border-primary rounded"
          >
            <strong>Login</strong>
            {/* <Button size="lg" variant="outline-primary">
              Join Beta
            </Button> */}
          </Nav.Link>
          <Link to="/">{/* <Navbar.Text className="ml-4">Pricing</Navbar.Text> */}</Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            href="https://app.plagiashield.com/app/signup"
            className="mx-2 bg-primary text-center text-white rounded"
          >
            <strong>Sign Up</strong>
            {/* <Button size="lg" variant="outline-primary">
              Join Beta
            </Button> */}
          </Nav.Link>
          <Link to="/">{/* <Navbar.Text className="ml-4">Pricing</Navbar.Text> */}</Link>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
