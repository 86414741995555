import React, { useState, useEffect } from "react";
import queryString from "query-string";

const getSearchParams = () => {
  const [searchParams, setSearch] = useState({});

  let search = null;
  if (typeof window !== "undefined") {
    if (typeof document !== "undefined") {
      search = document.location.search;
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (typeof document !== "undefined") {
        setSearch(document.location.search ? queryString.parse(document.location.search) : {});
      }
    }
  }, [search]);

  return searchParams;
};

const useQueryParam = (key, defaultState) => {
  const searchParams = getSearchParams();
  return searchParams[key] ? searchParams[key] : defaultState ? defaultState : null;
};

export default useQueryParam;
