import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";

// import { useStaticQuery, graphql } from "gatsby"

const Logo = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "app-icons/plagia-shield-logo.png" }) {
        childImageSharp {
          fixed(width: 100, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return <Img fixed={data.file.childImageSharp.fixed} />;
};

export default Logo;
