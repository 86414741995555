declare global {
    interface Window {
        ga: any
        gtag: any
        civchat: any
        userengage: any
        rewardful: any
    }
}

// ! AppWebID is specific to a stream (different for site and front)
const AppWebID: string = 'G-N3VJX9WH3S'
const googleTrackingID: string = 'UA-150387591-1'
const GoogleAdsID: string = 'AW-624545329'

// const analyticsIDs: string[] = [AppWebID, googleTrackingID]

// ############# BASE GOOGLE TRACKING FUNCTIONS

export function gtagTrackEvent(eventName: string) {
    try {
        if (typeof window !== 'undefined') {
            window.gtag('event', eventName)
        }
    } catch (error) {
        console.error(error)
    }
}

export function gtagTrackEventWithParameters(
    eventName: string,
    eventParameters: object
) {
    try {
        if (typeof window !== 'undefined') {
            window.gtag('event', eventName, { ...eventParameters })
        }
    } catch (error) {
        console.error(error)
    }
}

// ############# BASE UE TRACKING FUNCTIONS

// export function ueTrackEvent(eventName: string) {
//     try {
//         if (window.civchat) {
//             const event = 'event.' + eventName
//             window.userengage(event)
//         }
//     } catch (error) {
//         console.error(error)
//     }
// }

// export function ueTrackEventWithParameters(
//     eventName: string,
//     eventParameters: object
// ) {
//     try {
//         if (window.civchat) {
//             const event = 'event.' + eventName
//             window.userengage(event, eventParameters)
//         }
//     } catch (error) {
//         console.error(error)
//     }
// }

// export function ueUpdateClient(clientAttributes: object) {
//     try {
//         if (window.civchat) {
//             window.userengage('client.update', clientAttributes)
//         }
//     } catch (error) {
//         console.error(error)
//     }
// }

// ############# SET GA DIMENSIONS

function gtagSetDimensionAffiliate(tagID: string, affiliateID: string) {
    try {
        if (typeof window !== 'undefined') {
            window.gtag('config', tagID, {
                custom_map: { dimension1: affiliateID },
            })
        }
    } catch (error) {
        console.error(error)
    }
}

export function setGADimensionAffiliate(affiliateID: string) {
    gtagSetDimensionAffiliate(googleTrackingID, affiliateID)
    gtagSetDimensionAffiliate(AppWebID, affiliateID)
}

// ############# FILL PLAGIARISM CHECKER

export function trackTextAreaFilled() {
    // Ads
    gtagTrackEvent('plagia_text_area_filled')
    //   ueTrackEvent('plagia_text_area_filled')
}


// ############# VISIT FROM AFFILIATE

export function trackVisitFromAffiliate(affiliateID: string) {
    const url =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname

    // ueTrackEventWithParameters('affiliate_visit', {
    //     url: url,
    //     affiliate_id: affiliateID,
    // })

    gtagTrackEventWithParameters('visit_from_affiliate', {
        event_category: affiliateID,
    })
}


// ############# Check Plagiarism Button clicked

export function trackCheckPlagiarismButtonClicked() {
    // Ads
    gtagTrackEvent('check_plagia_button_clicked')
    // ueTrackEvent('check_plagia_button_clicked')
}